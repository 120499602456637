import React, { useState } from "react"
import ButtonGroup from "../Button/ButtonGroup"

const PaymentForm = () => {
  const [patientNumber, setPatientNumber] = useState("")
  const [amount, setAmount] = useState("")
  return (
    <div>
      <h4 className=" mt-0 has-text-centered">
        WESTWOOD ORAL SURGERY ASSOCIATES SECURE ONLINE PAYMENT
      </h4>
      <form
        action="https://www.eprocessingnetwork.com/cgi-bin/wo/order.pl"
        method="POST"
        target="_blank">
        <input name="ePNAccount" type="HIDDEN" value="0217427" />
        <input name="ItemQty" type="hidden" value="1" />
        <div className="has-text-centered mt-1 px-40--mobile">
          <span>Patient Name and/or Invoice #: </span>

          <input
            maxLength={40}
            name="ItemDesc"
            size={40}
            type="text"
            required
            value={patientNumber}
            onChange={e => setPatientNumber(e.target.value.trim())}
          />
        </div>
        <div className="has-text-centered mt-2 px-40--mobile">
          <span>Amount You Wish to Pay Today: </span>
          <input
            maxLength={8}
            name="ItemCost"
            size={8}
            type="number"
            required
            value={amount}
            onChange={e => setAmount(e.target.value.trim())}
          />
        </div>
        <ButtonGroup isCentered>
          <button type="submit" className="standard-button contained">
            Continue
          </button>
          <button
            type="reset"
            className="standard-button contained"
            onClick={() => {
              setPatientNumber("")
              setAmount("")
            }}>
            Reset
          </button>
        </ButtonGroup>
      </form>
    </div>
  )
}

export default PaymentForm
